import store from '@/store/index'
import router  from '@/router/index'

function getPageDesc(modulName) {
    let lang = store.getters.lang
    let state = store.state
    let module = state[modulName]
    return module[lang]
}

function goTomenu(menuName) {
    let menuItem = store.state.home.menuList[menuName]
    if (!menuItem) {
        console.log('menu item empty')
        return
    }
    console.log(menuItem)
    window.open(menuItem.linkUrl, '_blank')
}


function clickLocalMenu(menu) {
    router.push({name: menu.index})
}

export {
    getPageDesc,
    goTomenu,
    clickLocalMenu
}