import { createStore } from 'vuex'
import zhcnlib from 'element-plus/dist/locale/zh-cn.mjs'
import enlib from 'element-plus/dist/locale/en.mjs'
import home from './modules/home'
import login from './modules/login'
import device from './modules/device'
import user from './modules/user'
import agent from './modules/agent'
import dict from './modules/dict'


export default createStore({
  state: {
    curLang: '',
    langlist: [
      {value: 'zhcn', label: '简体中文', lib: zhcnlib, acceptLanguage: 'zh-CN'},
      {value: 'en', label: 'Engilish', lib: enlib, acceptLanguage: 'en-US'}
    ],
    acceptLanguage: '',
    nextRedirect: ''
  },
  getters: {
    currentLangue(state) {
      state.curLang = localStorage.getItem('curlang')
      if (!state.curLang) {
        state.curLang = 'en'
      }
      return state.langlist.filter(s => s.value == state.curLang)[0]
    },
    anotherLangs(state) {
      return state.langlist.filter(s => s.value != state.curLang)
    },
    lang: (state) => {
      if (!state.curLang) {
        state.curLang = localStorage.getItem('curlang')
      }
      return state.curLang
    },
    getLanguageHeader: (state) => {
      const lang = state.langlist.filter(s => s.value == state.curLang)[0]
      return lang.acceptLanguage
    },
  },
  mutations: {
    switchLanguage: (state, lang) => {
      state.curLang = lang

      localStorage.setItem('curlang', lang)
      // console.log('语言切换成功', state.curLang)
    }
  },
  actions: {
  },
  modules: {
    home,
    login,
    device,
    user,
    agent,
    dict
  }
})
