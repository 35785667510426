import store from ".."

export default {
  state: {
        zhcn: {
      slideText: '拖动滑块验证',
      menus: {
        CompanyManage:  { name: '公司管理', index: 'AgentCompanyMangement', path: '/agent/company/manage' },
        GroupsManage:    { name: '群组管理', index: 'GroupsManage', path: '/agent/groups/manage' },
        DeviceManage: { name: '用户管理', index: 'AgentDeviceManage', path: '/agent/devices/manage' },
      },
    },
    en: {
      slideText: 'Drag the slider below to complete the puzzle',
      menus: {
        CompanyManage:  { name: 'Companies Manage', index: 'AgentCompanyMangement', path: '/agent/company/manage' },
        GroupsManage:    { name: 'Groups Manage', index: 'GroupsManage', path: '/agent/groups/manage' },
        DeviceManage: { name: 'Device Manage', index: 'AgentDeviceManage', path: '/agent/devices/manage' },
      },
    }
  },
  getters: {
  },
  mutations: {

  },
  actions: {
    getAgentMenus: (context) => {
      let lang = context.getters.lang
      console.log(lang, context)
      let data = context.state[lang]
      let menus = []
      // let keys = state.menuList.keys()
      for (let key in data.menus) {
          menus.push(data.menus[key])
      }
      return menus
    }
  },
    modules: {

  }
}