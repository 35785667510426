import { getRegions } from '@/api/userapi'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { generaetRoutes } from '@/router/dynamicroute'
import cookies from 'vue-cookies'


const user = {
  state: {
    regions:[]
  },
  getters: {
    getRegions: (state) => {
      console.log('in getDictType', state)
      return state.regions || [];
    },
  },
  mutations: {
    SET_DICTS: (state, dictData) => {
      state[dictData.dictType] = dictData.list
    }
  },

  actions: {
    // 获取字典数据
    GetRegions({ commit, state }) {
      return new Promise((resolve, reject) => {
        getRegions().then(res => {
          let regions = []
          let list = res.data
          list.forEach(element => {
            let dictItem = {
              id: element.id,
              labels: {
                en: element.serverNameEn,
                zhcn: element.serverNameCn
              }
            }
            regions.push(dictItem)
          });
          let regionDict = {
            dictType: 'regions',
            list: regions
          }
          commit('SET_DICTS', regionDict)
          console.log('get regions', regionDict)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    }

}

export default user
