import { getUserInfo, getMenus, getClientUser, getMyCompany } from '@/api/userapi'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { generaetRoutes } from '@/router/dynamicroute'
import cookies from 'vue-cookies'


const user = {
  state: {
    token: getToken(),
    name: '',
    userInfo: {},
    avatar: '',
    roles: [],
    permissions: [],
    userMenus: [],
    userCompany: {}
  },
  getters: {
    getUserInfo: (state) => {
      console.log('in get user info')
      let jsonStr = cookies.get('user')
      console.log('jsonStr' , jsonStr)
      state.userInfo = jsonStr
      console.log('get cookie' , state.userInfo)
      return state.userInfo
    }
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
      state.name = userInfo.nickname
      console.log('set users info', state)
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
      },
      GENERATE_MENUS: (state, permissions) => {
          console.log('generate menus')
          let resMenus = permissions.filter(s => s.menuType == 'M')
          state.userMenus = resMenus

    },
    SET_USER_COMPANY: (state, company) => {
      state.userCompany = company
      console.log('company success', state)
    },
  },

  actions: {
    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
          getUserInfo().then(res => {
            const user = res.data
            console.log('set user info', user)
            commit('SET_NAME', user.name)
            commit('SET_USERINFO', user)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取用户信息
    GetCLientUser({ commit, state }) {
      return new Promise((resolve, reject) => {
          getClientUser().then(res => {
            let user = res.data
            console.log('set user info', user)
            commit('SET_NAME', user.nickname)
            commit('SET_USERINFO', user)
            // cookies.set('user', JSON.stringify(user))
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
      },
      GetMenus({ commit, state }) {
        return new Promise((resolve, reject) => {
            getMenus().then(res => {
                const menus = res.data
                commit('SET_PERMISSIONS', menus)
                commit('GENERATE_MENUS', menus)
                console.log('get permission success', state.permissions)
                // generaetRoutes()
                resolve(res)
            }).catch(error => {
          reject(error)
        })
      })
    },
    GetUserCompany({ commit, state }) {
      return new Promise((resolve, reject) => {
        getMyCompany().then(res => {
          let company = res.data
          console.log('company', company)
          // cookies.set('company', JSON.stringify(company))
          commit('SET_USER_COMPANY', company)
          resolve(res)
        })
      })
    }
    },

}

export default user
